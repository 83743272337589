.projects{
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    padding: 30px;
    overflow:auto;
    padding-bottom: 150px;
}

.projects::-webkit-scrollbar{
    display: none;
}


.project-preview{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background-color: white;
    border: 2px solid lightgray;
    height: 675px;
    border-radius: 15px;
    width: 35%;
    text-decoration: none;
}

.preview-pic{
    border: 2px solid lightgray;
    border-radius: 15px;
    width: 60%;
}

.preview-title{
    font-family: Quicksand;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0;
}

.in-progress{
    font-family: Quicksand;
    font-size: 25px;
    margin: 0;
    text-align: center;
}

.preview-oneliner{
    font-family: Quicksand;
    font-size: 20px;
    text-align: center;
}

#touchstone:hover{
    box-shadow: 1px 1px 20px 10px #4cf0b4;
}

#rekt:hover{
    box-shadow: 1px 1px 20px 10px #9579d1;
}

#riff:hover{
    box-shadow: 1px 1px 20px 10px #EC964A;
}

#wellmet:hover{
    box-shadow: 1px 1px 20px 10px #ffa5d8;
}

#whereartthou:hover{
    box-shadow: 1px 1px 20px 10px #F7665F;
}

#bwaybound:hover{
    box-shadow: 1px 1px 20px 10px darkgrey;
}

/* media queries */


/* tablet */
@media(max-width:1050px){
    .project-preview{
        width:45%;
        justify-content: space-between;
        height: 650px
    }
    .preview-title{
        font-size: 30px;
    }
    .in-progress{
        font-size: 22px;
    }
    .preview-oneliner{
        font-size: 20px;
    }
}

/* phone */
@media(max-width:550px){
    .projects{
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        height:fit-content;
    }
    .project-preview{
        width: 100%;
        height:fit-content;
    }
    .preview-title{
        font-size: 30px;
    }
    .in-progress{
        font-size: 22px;
    }
    .preview-oneliner{
        font-size: 18px;
    }
}



