.navbar{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    align-items: center;
    position: fixed;
    bottom: 2%;
}

.nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: min-content;
    height: 90%;
    border: 2px solid lightgrey;
    border-radius: 15px;
    background-color: white;
    gap: 15px;
    padding: 15px;
}

.navLink{
    border: 2px solid lightgray;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #f1f3f4;
}

#homeNav:hover{
    border: 2px solid grey; 
}

#projectNav:hover{
    border: 2px solid grey; 
}


#aboutNav:hover{
    border: 2px solid grey; 
}

#mailNav:hover{
    border: 2px solid grey;
    cursor: pointer;
}

#homeIcon{
    height: 100%
}

#projectIcon{
    height: 90%
}

#aboutIcon{
    height: 90%
}

#mailIcon{
    width: 90%
}

/* media queries */

