.homePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introBox {
  background-color: white;
  height: min-content;
  width: max-content;
  margin-top: 5%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 10px 10px 10px rgb(149, 121, 209, 0.3);
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;

  font-family: Quicksand;
  font-weight: bold;
}
.introName {
  font-size: 50px;
  font-family: Quicksand;
  font-weight: bold;
}
.introDescriptor {
  font-size: 35px;
  text-align: center;
}

/* background gradient */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gradient {
  --size: 1000px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(hsl(202, 55%, 67%, 1), hsl(326, 100%, 82%));
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  position: fixed;
  z-index: 0;
}

* {
  transition: all 0.5s ease-out;
}


/* media queries */

/* tablet */
@media (max-width: 1350px) {
  .introBox {
    margin-top: 10%;
  }

  .introName {
    font-size: 55px;
  }

  .introDescriptor {
    font-size: 38px;
  }

}

/* phone */
@media (max-width: 650px) {
  .introBox {
    margin-top: 30%;
  }

  .introName {
    font-size: 40px;
  }

  .introDescriptor {
    font-size: 23px;
  }

}


