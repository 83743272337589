.aboutPage{
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
    
}

#profileSection{
    margin-top: 30px;
    height: 100%;
    padding-bottom: 175px;
}
.aboutSection{
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 55px;
    height: 100%;
    overflow:auto;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 150px;
}

.aboutSection::-webkit-scrollbar{
    display: none;
}

.longerBio, .experience, .skills{
    border: 2px solid lightgray;
    background-color: white;
    border-radius: 15px;
    height: min-content;
    font-family: Quicksand;
    padding: 20px 30px;
    width: 90%
}

.longerBio{
    box-shadow: 1px 1px 20px 10px #9579d1;
}

.bio{
    font-size: 18px;
}

.skills{
    box-shadow: 1px 1px 20px 10px #fc57b4;
    display: flex;
    flex-direction: column;
}

.skill-sec{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.solo-skills{
    font-size: 18px;
    background-color: #F1F3F4;
    width:fit-content;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid lightgray;
    margin-top: 0;
    margin-bottom: 0;

}

.experience{
    box-shadow: 1px 1px 20px 10px #4cf0b4;
    height:fit-content;
}

.resume-div{
    text-align: right;
    font-size: 20px;
    font-weight: bold;
}

h5{
    font-family: Quicksand;
    font-weight:500;
    font-size: 17px;
}

/* media queries */


/* tablet */
@media(max-width:1050px){
    .aboutPage{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height:fit-content;
        width:100vw;
    }
    #profileSection{
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 5%;
        padding-bottom: 10px;
    }
    .aboutSection{
        width:85%;
      
    }
  }
  
  /* phone */
  @media(max-width:450px){
    .aboutPage{
        flex-direction: column;
        justify-content: center;
        height:fit-content;
        width:100vw;
    }
    #profileSection{
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 10%;
        padding-bottom: 10px;
    }
    .aboutSection{
        width:100%;
        padding-top: 20px;
    }
    .bio{
        font-size: 15px;
    }
    .solo-skills{
        font-size: 15px;
    }
    .longerBio{
        box-shadow: 1px 1px 15px 5px #9579d1;
    }
    .experience{
        box-shadow: 1px 1px 15px 5px #4cf0b4;
    }
    .skills{
        box-shadow: 1px 1px 15px 5px #fc57b4;
    }
    }
      /* short screens */

  @media(max-height:850px) and (min-width:1050px){
    #profileSection{
        overflow: auto;
        width: 40%;
        display: flex;
        justify-content: center;
        margin-top: 0;
        padding-top: 30px;
        padding-bottom: 145px;
    }

    #profileSection::-webkit-scrollbar{
        display: none;
    }
  }