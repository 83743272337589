.project{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:auto;
}
.project-riff{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    overflow: visible;
    padding-top: 30px;
    padding-bottom: 150px;
    z-index: 0;
    gap: 50px;
}

.project::-webkit-scrollbar{
    display: none;
}

/*image */
.project-riff-image{
    border: 2px, solid, lightgray;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    width: 30%
}
.project-image{
    border-radius: 15px;
}

/* project bio */
.project-riff-bio{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid lightgray;
    border-radius: 15px;
    background-color: white;
    width: 80%;
    padding: 10px 0;
}

/* title */
.project-riff-title{
    font-family: Quicksand;
    font-size: 50px;
    text-align: center;
}

/* skills */

.skill-sec{
    justify-content: center;
}

/* overview */
.project-overview-riff{
    width: 75%;
    font-family: Quicksand;
    font-size: 20px;
   
}

#project-overview-title-riff{
    font-family: Quicksand;
    font-size: 30px;
}

/* contribution */

.project-contribution-riff{
    width: 75%;
    font-family: Quicksand;
    font-size: 20px;
}

#project-contribution-title-riff{
    font-family: Quicksand;
    font-size: 30px;
}

/* links */
.project-links-riff{
    display: flex;
    gap: 50px;
    font-family: Quicksand;
    font-size: 30px;
    align-items: center;
  height: 100%;
}

.project-link-riff{
    text-decoration: none;
    background-color: white;
    padding: 10px;
    border: 2px, solid, lightgray;
    border-radius: 15px;
}

.project-link-riff:hover{
    box-shadow: 1px 1px 20px 10px #EC964A;
}

#gradient-riff{
    position:fixed;
    z-index: -1;
    background-image: linear-gradient( hsl(28, 81%, 61%, 1), hsl(326, 100%, 82%));
}

/* screens */

.project-riff-screens{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    gap: 30px;
    justify-content: center;
}

.project-screen-large-riff{
    width: 65%;
    border: 2px solid lightgray;
    border-radius: 15px;
}
.project-screen-small-riff{
    width: 40%;
    border: 2px solid lightgray;
    border-radius: 15px;
}

/* media queries */

/* tablet */
@media (max-width: 1050px) {
    .project-touchstone-image {
      width: 80%;
    }
    .project-links-riff {
      font-size: 20px;
      gap: 20px;
      width: 100%;
    }
  
    .project-link-riff {
      width: 40%;
      text-align: center;
    }
    .project-riff-bio {
      width: 100%;
    }
  
    .project-screen-large-riff {
      width: 100%;
    }
    .project-screen-small-riff {
      width: 45%;
    }
  }
  
  /* phone */
  @media (max-width: 450px) {
    .project-riff-image {
      width: 100%;
    }
    .project-links-riff {
      font-size: 20px;
      gap: 20px;
      flex-direction: column;
      width: 100%;
    }
  
    .project-link-riff {
      width: 75%;
      text-align: center;
    }
    .skill-sec{
      width: 95%;
    }
    .project-riff-bio {
      width: 100%;
    }
    .project-riff-title {
      font-size: 40px;
    }
    .project-overview-riff {
      font-size: 15px;
    }
  
    #project-overview-title-riff {
      font-family: Quicksand;
      font-size: 25px;
    }
    .project-contribution-riff {
      width: 75%;
      font-family: Quicksand;
      font-size: 15px;
    }
  
    #project-contribution-title-riff {
      font-family: Quicksand;
      font-size: 25px;
    }
    .project-riff-screens {
      flex-direction: column;
      align-items: center;
    }
  
    .project-screen-large-riff {
      width: 100%;
    }
    .project-screen-small-riff {
      width: 90%;
    }
  }
  