#profileCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    height: 100%;
    width: 450px;
    border: 2px solid lightgray;
    border-radius: 15px;
    background-color: white;
    font-family: Quicksand;
    box-shadow: 1px 1px 20px 10px #7eb8da;
}

#profilePic{
    width: 90%;
    border: 2px solid lightgray;
    border-radius: 15px;
}

.cardBio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px
}

#profileName{
    font-family: Quicksand;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0;
}

#profileMiniBio{
    font-size: 18px;
    text-align: center;
}

#buttonBar{
    display: flex;
    gap: 20px
}

.profileButtons{
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border: 2px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Linked In */
#linkedInButton{
    background-color: #92ddea;
}

#linkedInButton:hover{
    background-color: #7eb8da;
    border: 2px solid grey;
}

#linkedInIcon{
    height: 100%;
}

/* Git Hub */
#gitHubButton{
    background-color: #aaedd4;
}

#gitHubButton:hover{
    background-color: #4cf0b4;
    border: 2px solid grey;
}

#gitHubIcon{
    height: 100%;
}

/* Instagram */
#instaButton{
    background-color: #be9ddf;
}
#instaButton:hover{
    background-color: #9579d1;
    border: 2px solid grey;
}

#instaIcon{
    height: 85%;
}

/* Email */
#emailButton{
    background-color: #ffa5d8;
}
#emailButton:hover{
    background-color: #fc57b4;
    border: 2px solid grey;
}

/* media queries */


  /* short screens */


/* tablet */
@media(min-width: 850px) and (max-width:1049px){
   #profileCard{
    flex-direction: row;
    width:90%;
    justify-content: space-around;
    gap: 5px
   }
   #profilePic{
    width:50%;
   }
   #profileName{
    font-size: 45px;
}
#profileMiniBio{
    font-size: 20px;

}
}

/* phone */
@media(max-width:450px){
    #profileCard{
        width: 90%;
        height:fit-content
    }
    #profileName{
        font-size: 30px;
    }
    #profileMiniBio{
        font-size: 15px;
    }
    .profileButtons{
        height: 40px;
        width: 40px;
    }
  }

  /* short screens */

  @media(max-height:850px) and (min-width:1050px){
    #profileCard{
        height: 650px;
        width: 80%;
    }
    #profilePic{
        width: 60%;
    }
  }