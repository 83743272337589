.project{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:auto;
}

.project-bwaybound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    overflow: visible;
    padding-top: 30px;
    padding-bottom: 150px;
    z-index: 0;
    gap: 50px;
}

.project-bwaybound::-webkit-scrollbar{
    display: none;
}

/*image */
.project-bwaybound-image{
    border: 2px, solid, lightgray;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    width:30%;
}
.project-image{
    border-radius: 15px;
}

/* project bio */
.project-bwaybound-bio{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid lightgray;
    border-radius: 15px;
    background-color: white;
    width: 80%;
    padding: 10px 0;
}

/* title */
.project-bwaybound-title{
    font-family: Quicksand;
    font-size: 50px;
    text-align: center;
}

/* skills */

.skill-sec{
    justify-content: center;
}

/* overview */
.project-overview-bb{
    width: 75%;
    font-family: Quicksand;
    font-size: 20px;
   
}

#project-overview-title-bb{
    font-family: Quicksand;
    font-size: 30px;
}

/* contribution */

.project-contribution-bb{
    width: 75%;
    font-family: Quicksand;
    font-size: 20px;
}

#project-contribution-title-bb{
    font-family: Quicksand;
    font-size: 30px;
}

/* links */
.project-links-bb{
    display: flex;
    gap: 50px;
    font-family: Quicksand;
    font-size: 30px;

}

.project-link-bwaybound{
    text-decoration: none;
    background-color: white;
    padding: 10px;
    border: 2px, solid, lightgray;
    border-radius: 15px;
}

.project-link-bwaybound:hover{
    box-shadow: 1px 1px 20px 10px darkgray;
}

#gradient-bwaybound{
    position:fixed;
    z-index: -1;
    background-image: linear-gradient( hsl(0, 0%, 66%, 1), hsl(326, 100%, 82%));
}

/* screens */

.project-bwaybound-screens{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    gap: 30px;
    justify-content: center;
}

.project-screen-large-bb{
    width: 65%;
    border: 2px solid lightgray;
    border-radius: 15px;
}
.project-screen-small-bb{
    width: 40%;
    border: 2px solid lightgray;
    border-radius: 15px;
}

/* media queries */

/* tablet */
@media (max-width: 1050px) {
    .project-bwaybound-image {
      width: 80%;
    }
    .project-bwaybound-bio {
      width: 100%;
    }
  
    .project-screen-large-bb {
      width: 100%;
    }
    .project-screen-small-bb {
      width: 45%;
    }
  }
  
  /* phone */
  @media (max-width: 450px) {
    .project-bwaybound-image {
      width: 100%;
    }
    .project-links-bb {
      font-size: 25px;
      gap: 40px;
    }
    .project-bwaybound-bio {
      width: 100%;
    }
    .project-bwaybound-title {
      font-size: 40px;
    }
    .project-overview-bb {
      font-size: 15px;
    }
  
    #project-overview-title-bb {
      font-family: Quicksand;
      font-size: 25px;
    }
    .project-contribution-bb {
      width: 75%;
      font-family: Quicksand;
      font-size: 15px;
    }
  
    #project-contribution-title-bb {
      font-family: Quicksand;
      font-size: 25px;
    }
    .project-bwaybound-screens {
      flex-direction: column;
      align-items: center;
    }
  
    .project-screen-large-bb {
      width: 100%;
    }
    .project-screen-small-bb {
      width: 80%;
    }
  }
  