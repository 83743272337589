.project{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:auto;
}
.project-whereart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    overflow: visible;
    padding-top: 30px;
    padding-bottom: 150px;
    z-index: 0;
    gap: 50px;
}

.project::-webkit-scrollbar{
    display: none;
}

/*image */
.project-whereart-image{
    border: 2px, solid, lightgray;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    width: 30%;
}
.project-image{
    border-radius: 15px;
}

/* project bio */
.project-whereart-bio{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid lightgray;
    border-radius: 15px;
    background-color: white;
    width: 80%;
    padding: 10px 0;
}

/* title */
.project-whereart-title{
    font-family: Quicksand;
    font-size: 50px;
    text-align: center;
}

/* skills */

.skill-sec{
    justify-content: center;
}

/* overview */
.project-overview-wat{
    width: 75%;
    font-family: Quicksand;
    font-size: 20px;
   
}

#project-overview-title-wat{
    font-family: Quicksand;
    font-size: 30px;
}

/* contribution */

.project-contribution-wat{
    width: 75%;
    font-family: Quicksand;
    font-size: 20px;
}

#project-contribution-title-wat{
    font-family: Quicksand;
    font-size: 30px;
}

/* links */
.project-links-wat{
    display: flex;
    gap: 50px;
    font-family: Quicksand;
    font-size: 30px;

}

.project-link-whereart{
    text-decoration: none;
    background-color: white;
    padding: 10px;
    border: 2px, solid, lightgray;
    border-radius: 15px;
}

.project-link-whereart:hover{
    box-shadow: 1px 1px 20px 10px #F7665F;
}

#gradient-whereart{
    position:fixed;
    z-index: -1;
    background-image: linear-gradient( hsl(3, 90%, 67%, 1), hsl(326, 100%, 82%));
}

/* screens */

.project-whereart-screens{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    gap: 30px;
    justify-content: center;
}

.project-screen-large-wat{
    width: 65%;
    border: 2px solid lightgray;
    border-radius: 15px;
}
.project-screen-small-wat{
    width: 40%;
    border: 2px solid lightgray;
    border-radius: 15px;
}

/* media queries */

/* tablet */
@media (max-width: 1050px) {
    .project-whereart-image {
      width: 80%;
    }
    .project-whereart-bio {
      width: 100%;
    }
  
    .project-screen-large-wat {
      width: 100%;
    }
    .project-screen-small-wat {
      width: 45%;
    }
  }
  
  /* phone */
  @media (max-width: 450px) {
    .project-whereart-image {
      width: 100%;
    }
    .project-links-wat {
      font-size: 25px;
      gap: 40px;
    }
    .project-whereart-bio {
      width: 100%;
    }
    .project-whereart-title {
      font-size: 40px;
    }
    .project-overview-wat {
      font-size: 15px;
    }
  
    #project-overview-title-wat {
      font-family: Quicksand;
      font-size: 25px;
    }
    .project-contribution-wat {
      width: 75%;
      font-family: Quicksand;
      font-size: 15px;
    }
  
    #project-contribution-title-wat {
      font-family: Quicksand;
      font-size: 25px;
    }
    .project-whereart-screens {
      flex-direction: column;
      align-items: center;
    }
  
    .project-screen-large-wat {
      width: 100%;
    }
    .project-screen-small-wat {
      width: 80%;
    }
  }
  